<template>
  <div>
    <v-row align="center">
      <v-col cols="6" md="5">
        <page-header :title="title" :menu="menu" class="mx-2" />
      </v-col>
      <v-col cols="6" md="4" class="mt-6">
        <v-autocomplete
          dense
          class="mt-1"
          outlined
          :items="employees"
          item-value="id"
          item-text="person.corporateName"
          label="Profissional"
          v-model="professionalId"
          @change="getItems"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          required
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" block color="#2ca01c" dark>
              <v-icon>mdi-plus-thick</v-icon>
              Ações</v-btn
            >
          </template>
          <v-list>
            <v-list-item
              style="text-transform: none"
              width="8rem"
              text
              @click="openDialog = true"
            >
              <span>Cadastrar Agenda</span>
            </v-list-item>
            <v-list-item
              style="text-transform: none"
              width="8rem"
              text
              to="/base/sale/create?type=AGENDAMENTO"
            >
              <span>Novo Agendamento</span>
            </v-list-item>
            <v-list-item
              width="8rem"
              text
              to="/schedule/list"
              style="text-transform: none"
            >
              <span>Listagem da Agenda</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hoje
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mês</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 dias</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
                :first-time="firstTime"
                interval-minutes="30"
                first-interval="0"
                :interval-count="intervalCount"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-btn icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <span v-html="selectedEvent.details"></span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      v-if="selectedEvent.status === 'ABERTO'"
                      :to="
                        'base/sale/' +
                        selectedEvent.saleId +
                        '/edit?type=AGENDAMENTO'
                      "
                      text
                      color="secondary"
                    >
                      Alterar
                    </v-btn>
                    <v-btn
                      v-if="selectedEvent.status === 'ABERTO'"
                      dark
                      color="red"
                      @click="removeSale(selectedEvent.saleId)"
                    >
                      CANCELAR
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="950px">
        <v-card elevation="0">
          <v-toolbar color="primary" dark>
            <span class="headline" style="font-size: 16px !important"
              >Agenda</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    outlined
                    :items="employees"
                    item-value="id"
                    item-text="person.corporateName"
                    label="Profissional"
                    v-model="schedule.professionalId"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    type="date"
                    outlined
                    label="Data Inicial"
                    v-model="schedule.startDate"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    type="date"
                    outlined
                    label="Data Final"
                    v-model="schedule.endDate"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-for="(day, i) in days" :key="i">
                <v-col cols="2" md="3">
                  <v-checkbox
                    v-model="day.checked"
                    :label="day.label"
                    color="indigo"
                    :value="true"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="5" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Inicio"
                    v-model="day.startTime"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Fim"
                    v-model="day.endTime"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Inicio Intervalo"
                    v-model="day.startInterval"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    type="time"
                    outlined
                    label="Fim Intervalo"
                    v-model="day.endInterval"
                    required
                  ></v-text-field>
                </v-col>
                <hr class="mt-n4 mb-4" style="width: 100%; opacity: 0.2" />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Fechar
            </v-btn>
            <v-btn
              :loading="loadingAction"
              color="success"
              @click="registerOrUpdate()"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import moment from "moment";
export default Vue.extend({
  name: "Agenda",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Agenda",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Agenda",
        disabled: false,
        href: "/schedules",
      },
    ],
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "genetor.person.employees.get_many",
          },
        ],
      },
    ],
    schedules: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    employees: [],
    schedule: {
      name: "",
      professionalId: null,
      description: "",
      startDate: null,
      endDate: null,
      days: [],
    },
    openDialog: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "label",
        filtering: true,
      },
      {
        text: "Profissional",
        align: "start",
        value: "professional.person.corporateName",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
    search: null,

    days: [
      {
        dayOfWeek: 1,
        label: "SEG",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 2,
        label: "TER",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 3,
        label: "QUA",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 4,
        label: "QUI",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 5,
        label: "SEX",
        startTime: "08:00",
        endTime: "18:00",
        startInterval: "12:00",
        endInterval: "14:00",
        checked: true,
      },
      {
        dayOfWeek: 6,
        label: "SAB",
        startTime: "08:00",
        endTime: "12:00",
        startInterval: "00:00",
        endInterval: "00:00",
        checked: false,
      },
      {
        dayOfWeek: 7,
        label: "DOM",
        startTime: "08:00",
        endTime: "12:00",
        startInterval: "00:00",
        endInterval: "00:00",
        checked: false,
      },
    ],
    /**test schedule */
    focus: "",
    type: "day",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
      "4day": "4 Dias",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    start: "",
    end: new Date(),
    firstTime: "06:00:00",
    intervalCount: 30,
    professionalId: null,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
  }),
  methods: {
    /**start schedule */

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async updateRange() {
      //  this.schedules = this.schedules.filter((a) => a.saleId !== null);
      const events = [];

      for (const event of this.schedules) {
        events.push({
          name: event.sale
            ? event.sale.customer.person.corporateName
            : "Horário Vago",
          start: new Date(event.startTime),
          end: new Date(event.endTime),
          color:
            event.sale.status === "ABERTO"
              ? "blue"
              : event.sale.status === "CANCELADO"
              ? "red"
              : "green", //event.sale ? "" : "blue",
          timed: true,
          saleId: event.sale ? event.sale.id : null,
          status: event.sale ? event.sale.status : "LIVRE",
          details: `
          <p>Serviço: <strong>${event.label}</strong></p>
          <p>Contato: <strong>${
            event.sale.customer.contact.whatsApp
          }</strong></p>
          <p>Status: <strong>${event.sale.status}</strong></p>
                    <p>Horário: <strong>${moment(event.startTime).format(
                      "HH:mm"
                    )} - ${moment(event.endTime).format("HH:mm")}</strong></p>`,
        });
      }
      this.events = events;
      //  this.start = moment(this.schedules.startTime).format("HH:mm:ss");
    },

    /**end schedule */

    /**Remove schedule and sale */
    removeSale(saleId) {
      http.delete(`gerentor/sales/${saleId}`).then(() => {
        this.selectedOpen = false;
        this.getItems();
      });
    },

    getItems() {
      if (!this.professionalId) return;
      this.loading = true;
      http
        .get(
          `schedules?filter=professionalId||eq||${this.professionalId}&filter=saleId||notnull&filter=isMain||eq||true`
        )
        .then((data) => {
          this.schedules = data.data;
          this.updateRange();
          this.loading = false;
        });

      this.loading = false;
    },

    changeAuto(e) {
      //  console.log(e);
    },
    getEmployees() {
      this.loading = true;
      http.get("gerentor/employees").then((data) => {
        this.employees = data.data;
        if (this.employees.length > 0)
          this.professionalId = this.employees[0].id;
        if (this.professionalId) this.getItems();
        this.loading = false;
      });
    },
    registerOrUpdate() {
      const days = this.days.filter((a) => a.checked === true);
      this.schedule.days = days;
      this.loadingAction = true;
      if (this.schedule.id) {
        http.put(`schedules/${this.schedule.id}`, this.schedule).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("schedules", this.schedule).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      (this.schedule = {
        name: "",
        professionalId: null,
        label: "",
      }),
        this.getItems();
    },
    editItem(item) {
      this.schedule = item;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getEmployees();
    if (this.schedules.length) this.$refs.calendar.checkChange();
  },
});
</script>